import { Field } from 'hiber-grpc/src/customer_compiled/field';
import { Slot, VNode } from 'vue';
import { Comment, Fragment } from 'vue-demi';
import { OrganizationPermission, SupportPermission, UserPermission } from 'hiber-grpc/src/customer_compiled/permission';
import { Health } from 'hiber-grpc/src/customer_compiled/base';

function asArray(arg) {
  return Array.isArray(arg) ? arg : arg != null ? [arg] : [];
}

export const DATE_FORMAT = 'd MMM `yy HH:mm';

export enum State {
  LOADING = 'LOADING',
  READY = 'READY',
  DEFAULT = 'DEFAULT',
}

export const ALL_SENSORS_TAB_NAME = 'all';

export const EM_DASH = '—';

export function createUint8Array(string: string): Uint8Array {
  const contentBytes: number[] = [];
  for (let i = 0; i < string.length; i += 1) {
    contentBytes.push(string.charCodeAt(i));
  }

  return new Uint8Array(contentBytes);
}

export function convertHexToAscii(value: string | undefined): string {
  if (!value) return '';
  const hex = value.replace(/\s+/g, '');
  let str = '';
  for (let n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
}

export type DateRange = {
  start: Date | string;
  end: Date | string;
};

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
export type WithModemInfo = {
  modemNumber?: string;
  modemName?: string;
};
export type WithAssetInfo = {
  assetIdentifier?: string;
  assetName?: string;
};
export type WithColor = {
  color: string;
};
export type EnhancedField = Field & WithColor & WithModemInfo & WithAssetInfo;

function isVNodeEmpty(vnode: VNode[] | undefined): boolean {
  return (
    !vnode ||
    asArray(vnode).every(
      (vnode) =>
        vnode.type === Comment ||
        (vnode.type === Text && !vnode.children.trim()) ||
        (vnode.type === Fragment && isVNodeEmpty(vnode.children)),
    )
  );
}

function isSlotEmpty(slot: Slot | undefined, props = {}) {
  return isVNodeEmpty(slot?.(props));
}

export function hasSlotContent(slot: Slot | undefined, props = {}): boolean {
  return !isSlotEmpty(slot, props);
}

export const permissionsTotalCount = Object.keys(OrganizationPermission).filter(
  (i) => i !== OrganizationPermission.UNRECOGNIZED && i !== OrganizationPermission.PERMISSION_DEFAULT,
).length;
export const supportPermissionsTotalCount = Object.keys(SupportPermission).filter(
  (i) => i !== SupportPermission.UNRECOGNIZED,
).length;
export const userPermissionsTotalCount = Object.keys(UserPermission).filter(
  (i) => i !== UserPermission.UNRECOGNIZED,
).length;

export const getHealthColor = (health: Health): string => {
  switch (health) {
    case Health.ERROR:
      return 'bg-alert-red';
    case Health.WARNING:
      return 'bg-alert-orange';
    case Health.OK:
      return 'bg-alert-green';
    default:
      return '';
  }
};
