import useImpersonation from '@/hooks/useImpersonation';
import { watch } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';

export default function useResetQueryCache(): void {
  const impersonation = useImpersonation();
  const queryClient = useQueryClient();

  watch(impersonation, async (value, oldValue) => {
    if (oldValue !== value) {
      const queryCache = queryClient.getQueryCache();
      await queryCache.clear();
    }
  });
}
